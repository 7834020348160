import React, { useState } from 'react';
import styled from 'styled-components';
import { ClearIcon, SearchIcon, SparkIcon } from './Icons';

interface SearchBarProps {
  ingredients: string[];
  onSelect: (ingredient: string) => void;
  goButtonVisible?: boolean;
  goButtonText?: string;
  onGoClick?: (ingredient: string) => void;
  onClear?: () => void;
}

export function SearchBarWithSuggestions({
  ingredients,
  onSelect,
  goButtonVisible = false,
  goButtonText = "Generate",
  onGoClick,
  onClear,
}: SearchBarProps) {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    if (inputValue === '') {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    const filteredSuggestions = ingredients.filter((ingredient) =>
      ingredient.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setShowSuggestions(true);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    setShowSuggestions(false);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion);
    setShowSuggestions(false);
    setSuggestions([]);
    onSelect(suggestion);
  };

  const handleClearInput = () => {
    setQuery('');
    setSuggestions([]);
    setShowSuggestions(false);
    setIsFocused(false);
    onClear && onClear();
  };

  return (
    <SearchBar $focused={isFocused}>
      <SearchBox>
        <SearchSvgIcon>
          <SearchIcon />
        </SearchSvgIcon>
        <Input
          type="text"
          placeholder="Search ingredients..."
          value={query}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <ButtonContainer>
          {query && (
            <ClearSvgButton onMouseDown={handleClearInput}>
              <ClearIcon />
            </ClearSvgButton>
          )}
          <GoButtonWrapper $show={(query !== '' && goButtonVisible)}>
            <GoButton onClick={() => onGoClick && onGoClick(query)}>
              <SparkSvgIcon>
                <SparkIcon />
              </SparkSvgIcon>
              {goButtonText}
            </GoButton>
          </GoButtonWrapper>
        </ButtonContainer>
      </SearchBox>

      <AnimatedSuggestionsList $show={showSuggestions ? suggestions.length > 0 : false} $itemcount={suggestions.length}>
        {suggestions.map((suggestion, index) => (
          <SuggestionItem key={index} onMouseDown={() => handleSuggestionClick(suggestion)}>
            {suggestion}
          </SuggestionItem>
        ))}
      </AnimatedSuggestionsList>
    </SearchBar>
  );
}

const SearchBar = styled.div<{ $focused: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.$focused ? '100%' : '90%'};
  margin-bottom: 20px;
  transition: width 0.3s ease;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  background-color: #f6f6f6;
`;

const SearchBox = styled.div`
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const SearchSvgIcon = styled.div`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  svg {
    fill: #fa6e67;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

const ClearSvgButton = styled.div`
  cursor: pointer;
  margin-right: 12px;
  width: 16px;
  height: 16px;
  svg {
    fill: #fa6e67;
  }
  &:hover {
    svg {
      fill: #d86561;
    }
  }
`;

const SparkSvgIcon = styled.div`
  margin-right: 4px;
  width: 16px;
  height: 16px;
  g {
    stroke: #ffffff;
  }
`;

const Input = styled.input`
  width: calc(100% - 58px);
  height: 30px;
  padding: 8px 8px 8px 50px;
  border: 0;
  border-radius: 30px;
  font-size: 16px;
  color: #737373;
  &:focus {
    outline: none;
  }
`;

const AnimatedSuggestionsList = styled.div<{ $show: boolean; $itemcount: number }>`
  position: relative;
  top: 0px;
  left: 0px;
  margin: ${({ $show }) => ($show ? '10px' : '0 10px')};
  background-color: ${({ $show }) => ($show ? '#f6f6f6' : 'transparent')};
  border-radius: 15px;
  height: ${({ $show, $itemcount }) => ($show ? `${$itemcount * 36}px` : '0')};
  max-height: ${5 * 36}px;
  overflow-y: auto;
  transition: height 0.3s ease, margin 0.3s ease, background-color 0.3s ease;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fa6e6730;
    border: 1px solid #fa6e6750;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

const SuggestionItem = styled.div`
  padding: 8px;
  cursor: pointer;
  border-radius: 15px;
  height: 20px;
  &:hover {
    background-color: #e9f0f7;
  }
`;

const GoButtonWrapper = styled.div<{ $show: boolean }>`
  display: flex;
  overflow: hidden;
  max-width: ${({ $show }) => ($show ? '200px' : '0')};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transform: ${({ $show }) => ($show ? 'scale(1)' : 'scale(0.95)')};
  pointer-events: ${({ $show }) => ($show ? 'auto' : 'none')};
  transition: opacity 0.3s ease, transform 0.3s ease, max-width 0.3s ease;
`;

const GoButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  background-color: #fa6e67;
  color: #ffffff;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: #d86561;
  }

  &:active {
    background-color: #c25b57;
    transform: scale(0.95);
    transition: transform 0.1s;
  }
`;