import React from 'react'
import {
    Container,
    Headline
} from './StyledComponents'

export const NutrificientTerms = () => {
  return (
    <Container>
        <Headline>Terms and Conditions</Headline>
        <p>
            By downloading or using the Nutrificient app, these terms will automatically apply to you - you should make sure that you read them carefully before using the app. You are not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You are not allowed to attempt to extract the source code of the app, and you also should not try to translate the app into other languages or make derivative versions. The app itself, and all trademarks, copyright, database rights, and other intellectual property rights related to it, still belong to David Keter.
        </p>
        
        <Headline>Use of the App</Headline>
        <p>
        David Keter is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make changes to the app or to charge for its services at any time and for any reason. We will never charge you for the app or its services without making it very clear to you exactly what you're paying for.
        </p>

        <Headline>User Responsibilities</Headline>
        <p>
            The Nutrificient app stores and processes personal data that you have provided to us in order to provide our Service. It's your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to malware/viruses/malicious programs, compromise your phone's security features, and it could mean that the Nutrificient app won't work properly or at all.
        </p>

        <Headline>Changes to the App</Headline>
        <p>
            We are constantly updating the app to improve its functionality and services. The app is currently available on and iOS and Android - the requirements for the system (and for any additional systems we decide to extend the availability of the app to) may change, and you'll need to download the updates if you want to keep using the app. David Keter does not promise that it will always update the app to be relevant to you and/or work with the platform version that you have installed on your device.
        </p>

        <Headline>Third-Party Services</Headline>
        <p>
            The app does use third-party services that declare their own Terms and Conditions. Be aware that David Keter does not take responsibility for the way these third-party services work, so please review their terms and conditions when using their services through our app.
        </p>

        <Headline>Termination</Headline>
        <p>
            We may suspend or terminate your access to the app at any time without notice for conduct that we believe violates these Terms and Conditions or is harmful to other users, us, or third parties, or for any other reason.
        </p>

        <Headline>Limitations of Liability</Headline>
        <p>
            David Keter will not be liable for any losses you incur as a result of your use of the app or failure to use the app. We do not accept liability for any failure by the app to provide timely, accurate, or reliable information or services.
        </p>
        <p>
            In no event shall David Keter be responsible for any incidental, special, direct, indirect, punitive, or consequential damages of any kind arising out of your use of the app.
        </p>

        <Headline>Changes to These Terms and Conditions</Headline>
        <p>
            We may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Terms and Conditions on this page.
        </p>

        <p>These terms are effective as of September 2024.</p>

        <Headline>Contact Us</Headline>
        <p>
            If you have any questions or concerns about these Terms and Conditions, please contact us at davidketer@proton.me.
        </p>
    </Container>
  )
}
