import styled from 'styled-components';

export const GenericButton = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  color: #fa6e67;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    
  &:hover {
    background-color: #f3f8fc;
    transform: scale(1.05);
    transition: transform 0.1s;
  }

  &:active {
    background-color: #e9f0f7;
    transform: scale(0.95);
    transition: transform 0.1s;
  }
`;
