
export function SearchIcon() {
    return (
        <svg viewBox="0 0 283.5 283.5" xmlns="http://www.w3.org/2000/svg">
            <path d="m121.2 0c-67 0-121.3 54.3-121.3 121.3s54.3 121.3 121.3 121.3c26.6 0 51.2-8.5 71.1-23l60.2 60.2c5 5 13.1 5 18.1 0l9-9c5-5 5-13.1 0-18.1l-60.2-60.2c14.5-20 23-44.6 23-71.1.1-67.1-54.2-121.4-121.2-121.4zm-83 121.3c0-45.8 37.1-83 83-83 45.8 0 83 37.1 83 83 0 45.8-37.1 83-83 83-45.8-.1-83-37.2-83-83z" />
        </svg>
    );
}

export function ClearIcon() {
    return (
        <svg viewBox="0 0 283.5 283.5" xmlns="http://www.w3.org/2000/svg">
            <path clipRule="evenodd" fillRule="evenodd" d="m141.7 0c-78.2 0-141.7 63.5-141.7 141.7s63.5 141.7 141.7 141.7 141.8-63.4 141.8-141.7-63.5-141.7-141.8-141.7zm73.6 214.4-.9.9c-6.2 6.2-16.2 6.2-22.3 0l-50.4-50.3-50.3 50.3c-6.2 6.2-16.2 6.2-22.3 0l-.9-.9c-6.2-6.2-6.2-16.2 0-22.3l50.3-50.3-50.3-50.4c-6.2-6.2-6.2-16.2 0-22.3l.9-.9c6.2-6.2 16.2-6.2 22.3 0l50.2 50.2 50.3-50.3c6.2-6.2 16.2-6.2 22.3 0l.9.9c6.2 6.2 6.2 16.2 0 22.3l-50.3 50.3 50.4 50.3c6.3 6.3 6.3 16.3.1 22.5z" />
        </svg>
    );
}

export function SparkIcon() {
    return (
        <svg viewBox="0 0 212.6 212.6" xmlns="http://www.w3.org/2000/svg">
            <g clipRule="evenodd" fill="none" stroke="#000" strokeWidth="20" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                <path d="m58.4 54.4 26-41.2 26 41.2.3.3 41.2 26-41.2 26-.3.3-26 41.2-26-41.2-.3-.3-41.2-26 41.2-26z"/><path d="m145.5 149 14-22.1 14 22.1.1.1 22.1 14-22.1 14-.1.1-14 22.1-14-22.1-.1-.1-22.1-14 22.1-14z"/>
            </g>
        </svg>
    );
}
