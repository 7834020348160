import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { GenericButton } from './GenericButton';

interface EditorPopupProps {
    initialText: string;
    onClose: () => void;
    onConfirm: (newText: string) => void;
}

export function EditorPopup({ initialText, onClose, onConfirm }: EditorPopupProps) {
    const [text, setText] = useState<string>(initialText);
    
    const handleConfirm = () => {
        onConfirm(text);
    };

    const handleClickOutside = (event: MouseEvent) => {
        const popupElement = document.getElementById('popup-editor');
        if (popupElement && !popupElement.contains(event.target as Node)) {
            onClose();
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target.value);
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <PopupOverlay>
            <PopupContent id="popup-editor">
                <Title>Edit Sheet</Title>
                <Editor value={text} onChange={onChange} />
                <ButtonContainer>
                    <GenericButton onClick={handleConfirm}>OK</GenericButton>
                    <GenericButton onClick={onClose}>Cancel</GenericButton>
                </ButtonContainer>
            </PopupContent>
        </PopupOverlay>
    );
}

// -- Styles --

const PopupOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
`;

const PopupContent = styled.div`
    background: #1f1f1f;
    padding: 20px;
    gap: 20px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
`;

const Title = styled.h3`
    color: #ffffff;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const Editor = styled.textarea`
    width: 100%;
    height: 60vh;
    font-size: 12px;
    font-family: monospace;
    color: #ffffff;
    background-color: #1f1f1f;
    border: none;
    box-sizing: border-box;
    resize: none;
    outline: none;
    white-space: nowrap;
    overflow: auto;

    /* Custom scrollbar styles */
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }    
    &::-webkit-scrollbar-thumb {
        background-color: #ffffff30;
        border: 1px solid #ffffff50;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

