import styled from 'styled-components'

export const Container = styled.div`
  padding-left: 2em;
  padding-right: 2em;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`

export const Headline = styled.h1`
  font-weight: 700;
  font-size: 1.7em;
  color: #3d638a;
  line-height: 1em;

  @media (max-width: 768px) {
    font-size: 3em;
    text-align: center;
  }
`

export const StoreContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: left;
  justify-content: left;
`
export const Store = styled.button`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #F3F0DF;
  box-shadow: 0 6px 17px 0 rgba(0,0,0,0.07);
  border-radius: 6px;
  width: 240px;
  color: #3A2769;
  margin: 7px 10px;
  font-size: 13px;
  display: inline-block;
  padding: 15px 25px;
  text-align: left;
`
export const StoreLogo  = styled.img`
  display: inline-block;
  max-width: 100%;
  margin-right: 15px;
`
export const StoreButtonContainer = styled.div`
  text-align: left;
  display: inline-block;
`
export const StoreButtonHeader  = styled.div`
  /* margin-top: -13px; */
  font-size: 22px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
`

export const StoreButtonText  = styled.div`
  margin: 0px;
  /* margin-bottom: 7px; */
`
