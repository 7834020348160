import React, { FC } from 'react';
import { has, isUndefined } from 'lodash/fp';
import styled from 'styled-components';
import errorImage from './error.png';

interface ErrorPageParams {
  id?: string;
}

const errorConfig = {
  pageNotFound: {
    title: "Sorry...",
    message: "We can't find the page you're looking for",
    image: errorImage,
  },
  general: {
    title: "Oops...",
    message: "We're not sure what happened, please try again",
    image: errorImage,
  },
};

type ErrorId = keyof typeof errorConfig;

const isErrorId = (errorId?: string): errorId is ErrorId =>
  !isUndefined(errorId) && has(errorId, errorConfig);

export function ErrorPage({ id }: ErrorPageParams) {
  const errorId: ErrorId = isErrorId(id) ? id : 'general';

  const goBack = () => {
    window.history.back();
  };

  const { title, message, image } = errorConfig[errorId];

  return (
    <ErrorContainer>
      <ImageContainer>
        <Image width="200px" alt="Error" src={image} />
      </ImageContainer>
      <Title>{title}</Title>
      <Message>{message}</Message>
      <ButtonContainer>
        <Button onClick={goBack}>Go Back</Button>
      </ButtonContainer>
    </ErrorContainer>
  );
};

// -- Styles --

interface ImageProps {
  width: string;
}

const Image = styled.img<ImageProps>`
  width: ${({ width }) => width};
`;

const ErrorContainer = styled.div`
  display: flex;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const ImageContainer = styled.div`
  margin-top: 10em;
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29004e;
`;

const Message = styled.div`
  margin-top: 1em;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #29004e;
`;

const ButtonContainer = styled.div`
  margin-top: 2em;
  text-align: center;
`;

const Button = styled.button`
  width: 200px;
  height: 60px;
  border-radius: 30px;
  border: 0;
  background-color: #00ce77;
  font-size: 19px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
`;
