import React, { Component, ErrorInfo } from 'react'
import { ErrorPage } from './ErrorPage'

type ErrorBoundaryProps = {
  children: React.ReactNode
}

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
  public state = {
    hasError: false
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //logAnalyticsEvent(errorEvent(`${error.message}: ${errorInfo.componentStack}`))
    console.log(`${error.message}: ${errorInfo.componentStack}`)
  }

  render() {
    return this.state.hasError ? <ErrorPage /> : this.props.children
  }
}
