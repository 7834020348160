export interface IngredientData {
  id: string;
  name: {
    en: string;
    de: string;
    es: string;
    fr: string;
    it: string;
    ja: string;
    ko: string;
    pt: string;
    zhHans: string;
    zh: string;
    sv: string;
    ru: string;
  };
  category: string;
  possibleUnits: string[];
  conversionFactors: number[];
  costPerUnit: number;
  expirationDays: number;
  image: string;
  nutrition: {
    macronutrients: {
      calories_kcal: number;
      carbohydrates_g: number;
      fiber: {
        solubleFiber_g: number;
        insolubleFiber_g: number;
      };
      sugars: {
        glucose_g: number;
        fructose_g: number;
        sucrose_g: number;
        lactose_g: number;
        addedSugars_g: number;
        sugarAlcohols_g: number;
      };
      protein_g: number;
      fat: {
        saturatedFat_g: number;
        monounsaturatedFat_g: number;
        polyunsaturatedFat_g: number;
        transFat_g: number;
      };
      cholesterol_mg: number;
      water_g: number;
    };
    vitamins: {
      vitaminA_mcg: number;
      vitaminC_mg: number;
      vitaminD_mcg: number;
      vitaminE_mg: number;
      vitaminK_mcg: number;
      thiamin_vitaminB1_mg: number;
      riboflavin_vitaminB2_mg: number;
      niacin_vitaminB3_mg: number;
      pantothenicAcid_vitaminB5_mg: number;
      vitaminB6_mg: number;
      vitaminB12_mcg: number;
      folate_mcg: number;
      choline_mg: number;
    };
    minerals: {
      calcium_mg: number;
      iron_mg: number;
      magnesium_mg: number;
      phosphorus_mg: number;
      potassium_mg: number;
      sodium_mg: number;
      zinc_mg: number;
      copper_mg: number;
      manganese_mg: number;
      selenium_mcg: number;
      ash_g: number;
    };
    fattyAcids: {
      omega3_g: number;
      omega6_g: number;
      DHA_g: number;
      EPA_g: number;
      linoleicAcid_g: number;
      docosapentaenoicAcid_DPA_g: number;
      alphaLinolenicAcid_g: number;
      oleicAcid_g: number;
      erucicAcid_g: number;
      stearicAcid_g: number;
    };
    otherNutrients: {
      caffeine_mg: number;
      theobromine_mg: number;
      lutein_mcg: number;
      zeaxanthin_mcg: number;
      prebiotics_g: number;
      probiotics_g: number;
      phytosterols_mg: number;
      polyphenols_mg: number;
      organicAcids_mg: number;
    };
    aminoAcids: {
      leucine_g: number;
      lysine_g: number;
      valine_g: number;
      isoleucine_g: number;
      phenylalanine_g: number;
      methionine_g: number;
      tryptophan_g: number;
      histidine_g: number;
      tyrosine_g: number;
      cystine_g: number;
    };
  };
}

export const nutrientDisplayNames: { [key: string]: string } = {
  calories_kcal: "Calories",
  carbohydrates_g: "Carbs",
  protein_g: "Protein",
  water_g: "Water",
  cholesterol_mg: "Cholesterol",

  // Fiber
  solubleFiber_g: "Soluble Fiber",
  insolubleFiber_g: "Insoluble Fiber",

  // Sugars
  glucose_g: "Glucose",
  fructose_g: "Fructose",
  sucrose_g: "Sucrose",
  lactose_g: "Lactose",
  addedSugars_g: "Added Sugars",
  sugarAlcohols_g: "Sugar Alcohols",

  // Fat
  saturatedFat_g: "Saturated Fat",
  monounsaturatedFat_g: "Monounsaturated Fat",
  polyunsaturatedFat_g: "Polyunsaturated Fat",
  transFat_g: "Trans Fat",

  // Vitamins
  vitaminA_mcg: "Vitamin A",
  vitaminC_mg: "Vitamin C",
  vitaminD_mcg: "Vitamin D",
  vitaminE_mg: "Vitamin E",
  vitaminK_mcg: "Vitamin K",
  thiamin_vitaminB1_mg: "Thiamin (B1)",
  riboflavin_vitaminB2_mg: "Riboflavin (B2)",
  niacin_vitaminB3_mg: "Niacin (B3)",
  pantothenicAcid_vitaminB5_mg: "Pantothenic Acid (B5)",
  vitaminB6_mg: "Vitamin B6",
  vitaminB12_mcg: "Vitamin B12",
  folate_mcg: "Folate",
  choline_mg: "Choline",

  // Minerals
  calcium_mg: "Calcium",
  iron_mg: "Iron",
  magnesium_mg: "Magnesium",
  phosphorus_mg: "Phosphorus",
  potassium_mg: "Potassium",
  sodium_mg: "Sodium",
  zinc_mg: "Zinc",
  copper_mg: "Copper",
  manganese_mg: "Manganese",
  selenium_mcg: "Selenium",
  ash_g: "Ash",

  // Fatty Acids
  omega3_g: "Omega-3",
  omega6_g: "Omega-6",
  DHA_g: "DHA",
  EPA_g: "EPA",
  linoleicAcid_g: "Linoleic Acid",
  docosapentaenoicAcid_DPA_g: "DPA",
  alphaLinolenicAcid_g: "Alpha-Linolenic Acid",
  oleicAcid_g: "Oleic Acid",
  erucicAcid_g: "Erucic Acid",
  stearicAcid_g: "Stearic Acid",

  // Other Nutrients
  caffeine_mg: "Caffeine",
  theobromine_mg: "Theobromine",
  lutein_mcg: "Lutein",
  zeaxanthin_mcg: "Zeaxanthin",
  prebiotics_g: "Prebiotics",
  probiotics_g: "Probiotics",
  phytosterols_mg: "Phytosterols",
  polyphenols_mg: "Polyphenols",
  organicAcids_mg: "Organic Acids",

  // Amino Acids
  leucine_g: "Leucine",
  lysine_g: "Lysine",
  valine_g: "Valine",
  isoleucine_g: "Isoleucine",
  phenylalanine_g: "Phenylalanine",
  methionine_g: "Methionine",
  tryptophan_g: "Tryptophan",
  histidine_g: "Histidine",
  tyrosine_g: "Tyrosine",
  cystine_g: "Cystine"
};

export const nutrientUnits: { [key: string]: string } = {
  calories_kcal: "kcal",
  carbohydrates_g: "g",
  protein_g: "g",
  water_g: "g",
  cholesterol_mg: "mg",

  // Fiber
  solubleFiber_g: "g",
  insolubleFiber_g: "g",

  // Sugars
  glucose_g: "g",
  fructose_g: "g",
  sucrose_g: "g",
  lactose_g: "g",
  addedSugars_g: "g",
  sugarAlcohols_g: "g",

  // Fat
  saturatedFat_g: "g",
  monounsaturatedFat_g: "g",
  polyunsaturatedFat_g: "g",
  transFat_g: "g",

  // Vitamins
  vitaminA_mcg: "mcg",
  vitaminC_mg: "mg",
  vitaminD_mcg: "mcg",
  vitaminE_mg: "mg",
  vitaminK_mcg: "mcg",
  thiamin_vitaminB1_mg: "mg",
  riboflavin_vitaminB2_mg: "mg",
  niacin_vitaminB3_mg: "mg",
  pantothenicAcid_vitaminB5_mg: "mg",
  vitaminB6_mg: "mg",
  vitaminB12_mcg: "mcg",
  folate_mcg: "mcg",
  choline_mg: "mg",

  // Minerals
  calcium_mg: "mg",
  iron_mg: "mg",
  magnesium_mg: "mg",
  phosphorus_mg: "mg",
  potassium_mg: "mg",
  sodium_mg: "mg",
  zinc_mg: "mg",
  copper_mg: "mg",
  manganese_mg: "mg",
  selenium_mcg: "mcg",
  ash_g: "g",

  // Fatty Acids
  omega3_g: "g",
  omega6_g: "g",
  DHA_g: "g",
  EPA_g: "g",
  linoleicAcid_g: "g",
  docosapentaenoicAcid_DPA_g: "g",
  alphaLinolenicAcid_g: "g",
  oleicAcid_g: "g",
  erucicAcid_g: "g",
  stearicAcid_g: "g",

  // Other Nutrients
  caffeine_mg: "mg",
  theobromine_mg: "mg",
  lutein_mcg: "mcg",
  zeaxanthin_mcg: "mcg",
  prebiotics_g: "g",
  probiotics_g: "g",
  phytosterols_mg: "mg",
  polyphenols_mg: "mg",
  organicAcids_mg: "mg",

  // Amino Acids
  leucine_g: "g",
  lysine_g: "g",
  valine_g: "g",
  isoleucine_g: "g",
  phenylalanine_g: "g",
  methionine_g: "g",
  tryptophan_g: "g",
  histidine_g: "g",
  tyrosine_g: "g",
  cystine_g: "g"
};
