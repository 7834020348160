// LoginBoundary.tsx
import React, { useState, useEffect, ReactNode } from 'react';
import { auth, onAuthStateChanged } from './firebase';
import { LoginPage } from './LoginPage';

interface LoginBoundaryProps {
  children: ReactNode;
}

export function LoginBoundary({ children }: LoginBoundaryProps) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return <>{children}</>;
  // return isAuthenticated ? <>{children}</> : <LoginPage />;
};
