import React from 'react';
import { signOut, getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { LoginRoute } from '../config';

export function LogoutButton() {
    const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      console.log('User signed out');
      navigate(LoginRoute);
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  return (
    <button onClick={handleLogout}>
      Log Out
    </button>
  );
};
