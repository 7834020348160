import React from 'react'
import {
    Container,
    Headline
} from './StyledComponents'

export const NutrificientPrivacy = () => {
  return (
    <Container>
        <Headline>Privacy Policy</Headline>
        <p>
            David Keter built the Nutrificient app as a Free app. This SERVICE is provided by David Keter and is intended for use as described in this policy.
        </p>
        <p>
            This page is used to inform visitors regarding our policies on the collection, use, and disclosure of Personal Information for those who decide to use our Service.
        </p>
        <p>
            By choosing to use our Service, you agree to the collection and use of information in relation to this policy. The Personal Information we collect is used to provide and improve the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
        </p>

        <Headline>Information Collection and Use</Headline>
        <p>
            For a better experience, while using our Service, we may ask you to provide certain personally identifiable information, such as your name, email address, or usage data. This information may be collected by the app or through third-party services. The data will be retained only as long as necessary to provide the intended features of the Service.
        </p>

        <Headline>Log Data</Headline>
        <p>
            In case of an error in the app, we collect data and information through third-party products on your phone called Log Data. This may include information such as your device's Internet Protocol (IP) address, device name, operating system version, app configuration, time and date of use, and other statistics.
        </p>

        <Headline>Cookies</Headline>
        <p>
            Cookies are files with small amounts of data that may be used as unique identifiers. These are sent to your browser from websites and stored on your device's internal memory. Our Service may use third-party libraries or code that collect and use cookies to enhance the Service.
        </p>
        <p>
            You can choose to accept or refuse cookies and know when a cookie is being sent to your device. However, some parts of the Service may not function properly if cookies are disabled.
        </p>

        <Headline>Service Providers</Headline>
        <p>
            We may employ third-party companies and individuals for the following reasons:
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
        </p>
        <p>
            These third parties may have access to your Personal Information to perform their tasks. However, they are obligated not to disclose or use the information for any other purpose.
        </p>

        <Headline>Security</Headline>
        <p>
            We value your trust in providing us your Personal Information. We use commercially reasonable methods to protect it, but remember that no method of transmission over the internet or electronic storage is 100% secure. We cannot guarantee absolute security.
        </p>

        <Headline>Links to Other Sites</Headline>
        <p>
            Our Service may contain links to third-party websites. If you click on a third-party link, you will be directed to that site. Please note that we do not operate these external sites. Therefore, we advise you to review their Privacy Policies. We are not responsible for the content or practices of these third-party sites.
        </p>

        <Headline>Children's Privacy</Headline>
        <p>
            We do not knowingly collect personal data from children under the age of 13. If we discover that a child under 13 has provided us with personal information, we will delete it from our servers. If you are a parent or guardian and believe your child has provided us with personal information, please contact us so we can take necessary actions.
        </p>

        <Headline>Your Data Rights</Headline>
        <p>
            Depending on your jurisdiction, you may have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at the provided contact details below.
        </p>

        <Headline>Changes to This Privacy Policy</Headline>
        <p>
            We may update our Privacy Policy from time to time. You are advised to review this page periodically for any changes. We will notify you of updates by posting the new Privacy Policy on this page.
        </p>

        <p>This policy is effective as of September 2024.</p>

        <Headline>Contact Us</Headline>
        <p>
            If you have any questions or suggestions about this Privacy Policy, do not hesitate to contact us at davidketer@proton.me.
        </p>
    </Container>
  )
}
