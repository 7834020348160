
export const BackgroundColor = '#f3f8fc';
export const CardBackground = '#fff';
export const BorderColor = '#e0e0e0';
export const PrimaryColor = '#007bff';
export const PrimaryColorHover = '#0056b3';
export const ElementBackground = '#f1f1f1';

export const OverlayBackgroundColor = '#00000080';
export const OverlayTextColor = BackgroundColor;

