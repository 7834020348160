import { useState, useEffect } from 'react';
import * as API from '../../api';
import { User } from 'firebase/auth';

export function useIngredientData(user: User | null) {
    const [ingredients, setIngredients] = useState<string[]>([]);

    useEffect(() => {
        async function fetchIngredients() {
            try {
                const response = await API.fetchIngredientsList(user);
                setIngredients(response);
            } catch (error) {
                console.error('Error fetching ingredients:', error);
            }
        }
        fetchIngredients();
    }, [user]);

    const fetchIngredientDetails = async (ingredient: string) => {
        const response = await API.fetchIngredientDetails(user, ingredient);
        return response;
    };

    return { ingredients, fetchIngredientDetails };
}
