import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';

interface FullHeightComponentProps {
  isBottomVisible: boolean;
  maxBottomHeight: number;
  topChildren: ReactNode;
  bottomChildren: ReactNode;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
`;

const TopSection = styled.div<{ $bottomvisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: ${(props) => (props.$bottomvisible ? 0 : 1)};
  transition: flex-grow 0.4s ease;
  padding: 20px;
`;

const BottomSection = styled.div<{ $visible: boolean, $maxheight: number }>`
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  height: ${(props) => (props.$visible ? 'auto' : '0')};
  max-height: ${(props) => (props.$visible ? `${props.$maxheight}px` : '0')};
  opacity: ${(props) => (props.$visible ? '1' : '0')};
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
`;

export function FullHeightComponent({ isBottomVisible, maxBottomHeight, topChildren, bottomChildren }: FullHeightComponentProps) {
  return (
    <Container>
      <TopSection $bottomvisible={isBottomVisible}>
        {topChildren}
      </TopSection>
      <BottomSection $visible={isBottomVisible} $maxheight={maxBottomHeight}>
        {bottomChildren}
      </BottomSection>
    </Container>
  );
}
