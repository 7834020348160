import { useState } from 'react';
import styled from 'styled-components';
import { useAuth, fetchWithAuth } from '../authentication/useAuth';
import * as Theme from '../common/theme';


export function PassgenComponent() {
  const { user } = useAuth();
  const [name, setName] = useState('');
  const [site, setSite] = useState('');
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!name || !site) return;

    try {
      const url = 'https://keter.cloud/api/v1/passgen';
      const result = await fetchWithAuth(user, `${url}?name=${name}&site=${site}`, { method: 'GET' });
      setPassword(result.pw);
      try {
        await navigator.clipboard.writeText(result.pw);
        alert('Password copied to clipboard!');
      } catch (clipboardError) {
        console.error("Clipboard error:", clipboardError);
        alert("Could not copy to clipboard. Please copy manually.");
      }
      setError(null);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h2>Passgen</h2>
        <Input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          type="text"
          placeholder="Site"
          value={site}
          onChange={(e) => setSite(e.target.value)}
        />
        <Button type="submit">Generate & Copy</Button>
        {error && <Error>Error: {error}</Error>}
        {password && (
          <Result>
            <pre>{password}</pre>
          </Result>
        )}
      </Form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${Theme.BackgroundColor};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border-radius: 12px;
  background-color: ${Theme.CardBackground};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid ${Theme.BorderColor};
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  background-color: ${Theme.PrimaryColor};
  color: #fff;
  cursor: pointer;

  &:hover {
    background-color: ${Theme.PrimaryColorHover};
  }
`;

const Error = styled.div`
  color: red;
`;

const Result = styled.div`
  text-align: center;
  color: ${Theme.PrimaryColor}30;
`;
