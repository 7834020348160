import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../authentication/useAuth';
import { LoadingOverlay } from '../../common/components/LoadingOverlay';
import { SearchBarWithSuggestions } from './components/SearchBarWithSuggestions';
import { FullHeightComponent } from './components/FullHeightComponent';
import { IngredientDetails } from './components/IngredientDetails';
import { EditorPopup } from './components/EditorPopup';
import { useIngredientData } from './hooks/useIngredientData';
import { IngredientData } from './model/ingredient-model';
import { GenericButton } from './components/GenericButton';


type LoadingContextType = {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setLoading: () => { },
});

export const useLoading = () => useContext(LoadingContext);

export function GenerateIngredients() {
  const { user } = useAuth();
  const { ingredients, fetchIngredientDetails } = useIngredientData(user);
  const [ingredientExists, setIngredientExists] = useState<boolean>(false);
  const [ingredientImage, setIngredientImage] = useState<string>('');
  const [ingredientData, setIngredientData] = useState<IngredientData | null>(null);
  const [editorPopupVisible, setEditorPopupVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetchIngredientDetails = async (ingredient: string) => {
    setIsLoading(true);
    try {
      const { image, data } = await fetchIngredientDetails(ingredient);
      setIngredientImage(URL.createObjectURL(image));
      setIngredientData(data);
      setIngredientExists(true);
    } catch (error) {
      console.error('Error fetching ingredient details:', error);
      setIngredientExists(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading: setIsLoading }}>
      <Page>
        <FullHeightComponent
          isBottomVisible={ingredientExists}
          maxBottomHeight={1024}
          topChildren={
            <Center>
              <Title>Ingredient Database</Title>
              <SearchBarWithSuggestions
                ingredients={ingredients}
                onSelect={handleFetchIngredientDetails}
                goButtonVisible={!ingredientExists}
                onClear={() => setIngredientExists(false)}
              />
              <div>Total: {ingredients.length} ingredients</div>
            </Center>
          }
          bottomChildren={
            <>
              <IngredientDetails
                ingredientData={ingredientData}
                ingredientImage={ingredientImage}
              />
              <Center>
                <GenericButton onClick={() => setEditorPopupVisible(true)}>Edit</GenericButton>
              </Center>
            </>
          }
        />
        {editorPopupVisible && (
          <EditorPopup
            initialText={JSON.stringify(ingredientData, null, 2)}
            onClose={() => setEditorPopupVisible(false)}
            onConfirm={() => { }}
          />
        )}
        <LoadingOverlay show={isLoading} />
      </Page>
    </LoadingContext.Provider>
  );
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background: #fa6e67;
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 1024px;
  width: 80vw;
`;
const Title = styled.h1`
  padding-top: 10px;
  margin-bottom: 30px;
  font-size: 42px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  font-family: 'Vegan Style';
`;
