import React from 'react';
import styled from 'styled-components';
import LoadingSpinner from "./loading-spinner";
import { OverlayBackgroundColor, OverlayTextColor } from '../theme';

export const LoadingText = styled.div`
    font-size: 20px;
    font-weight: bold;
    color: ${OverlayTextColor};
    padding: 10px;
`;
export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${OverlayBackgroundColor};
    z-index: 100;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
`;

function prevent(e: any) {
    e.preventDefault();
    e.stopPropagation();
}

export function LoadingOverlay({ show }: any) {
    return (
        <>
        {show && (
            <Overlay onClick={prevent}>
                <LoadingSpinner />
                <LoadingText>Loading...</LoadingText>
            </Overlay>
        )}
        </>
    )
}