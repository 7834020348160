import React from 'react'
import {
    Container,
    Headline,
    StoreContainer,
    Store,
    StoreLogo,
    StoreButtonContainer,
    StoreButtonHeader,
    StoreButtonText
  } from './StyledComponents'
  import appleLogo from './apple.svg'

export const NutrificientLanding = () => {
    const openAppStore = () => {
        // window.location.href = 'https://apps.apple.com/us/app/'
    }
  return (
    <Container>
        <Headline>Nutrificient: Meal Planner</Headline>
        <StoreContainer>
          <Store onClick={openAppStore} >
            <StoreLogo src={appleLogo} />
            <StoreButtonContainer >
              <StoreButtonText>Download on the </StoreButtonText>
              <StoreButtonHeader>App Store</StoreButtonHeader>
            </StoreButtonContainer>
          </Store>
        </StoreContainer>
        <p>
            Contact me at: davidketer@proton.me
        </p>
    </Container>
  )
}
