import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './useAuth';

interface PrivateRouteProps {
  element: React.ReactElement;
}

export function PrivateRoute({ element }: PrivateRouteProps) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" />;
}
