import { fetchWithAuth } from "../authentication/useAuth";
import { User } from "firebase/auth";
import { IngredientData } from "./generate/model/ingredient-model";

export async function fetchIngredientsList(user: User | null) {
    const response = await fetchWithAuth(user, 'https://keter.cloud/api/v1/nutrificient/ingredients', { method: 'GET' });
    return response as string[];
};

export async function fetchIngredientDetails(user: User | null, ingredient: string) {
    const response = await fetchWithAuth(user, `https://keter.cloud/api/v1/nutrificient/ingredient/${ingredient}`, { method: 'GET' });
    const image = await fetch(response.image.url).then((res) => res.blob());
    const data = await fetch(response.data.url).then((res) => res.json()) as IngredientData
    return { image, data };
};
